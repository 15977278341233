<template>

  <div class="login login-v2 width-450">
    <div class="login-header">
      <div class="brand text-center">
        <i class="fas fa-exclamation-triangle"></i> <span class="text-first-letter">{{ 'System in maintenance' | translate }}</span>
      </div>
    </div>
    <div class="login-content">
      <h3 class="text-muted font-weight-normal mb-4 text-center">
        <span class="text-first-letter">{{ 'This operation is not possible at this time'|translate }}.</span>
      </h3>
      <h3 class="text-muted font-weight-normal mb-4 text-center">
        <span class="text-first-letter">{{ 'Please try again later'|translate }}.</span>
      </h3>
      <button
        class="btn btn-success btn-block btn-lg"
        @click.prevent="goToDashboard()"
      >
          {{ 'Go to dashboard' | translate }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
  methods: {
    goToDashboard () {
      this.$router.replace('/')
    }
  }
}
</script>
